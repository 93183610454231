import { CustomerListItemModel } from "../../../interfaces/models/customer-list-item.model";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { IoEyeOutline, IoWalletOutline } from "react-icons/io5";
import { ConvertToCurrency } from "../../../utils/convert-to-currency";
import { BiMoneyWithdraw } from "react-icons/bi";
import { ModalCustomerDetails } from "./modal-customer-details";
import { ModalRegisterCashbackUsage } from "./modal-register-cashback-usage";
import clsx from "clsx";
import { useRefreshStore } from "../../../stores/refresh.store";
import { Tooltip } from "react-tooltip";
import TooltipButton from "../../../components/tooltip";

interface Props {
  customers: CustomerListItemModel[];
}

export const TableCustomer = ({ customers }: Props) => {
  const navigate = useNavigate();

  const { isRefreshing: loading } = useRefreshStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  function handleOpenDetailsModal(customerId: string) {
    setSearchParams({ details: customerId });
  }

  function handleOpenUseCashbackModal(customerId: string) {
    setSearchParams({ useCashback: customerId });
  }

  return (
    <div className="col-xl-9 mb-4">
      <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center mb-3">
        <h2 className="fs-4">Registro de clientes</h2>

        {/* <SearchFilter /> */}
      </div>

      {loading && <p>Carregando...</p>}

      {!loading && customers.length === 0 && (
        <div className="alert alert-warning" role="alert">
          Nenhum registro encontrado
        </div>
      )}

      {!loading && customers.length > 0 && (
        <div className="table-responsive">
          <table className="table table-changeback">
            <thead className="table-light">
              <tr>
                <td>Cliente</td>
                <td>Saldo</td>
                <td>Data máxima expiração</td>
                <td>Data mínima expiração</td>
                <td>Ações</td>
              </tr>
            </thead>
            <tbody className="align-middle">
              {customers.map((customer) => (
                <tr key={customer.id}>
                  <td data-id>{customer.customerName}</td>
                  <td
                    className={clsx(
                      "fw-bold",
                      customer.balance < 0 && "text-danger",
                      customer.balance > 0 && "text-success",
                    )}
                  >
                    {ConvertToCurrency(customer.balance)}
                  </td>
                  <td>
                    {moment(customer.maxExpirationDate).format("DD/MM/YYYY")}
                  </td>
                  <td>
                    {moment(customer.minExpirationDate).format("DD/MM/YYYY")}
                  </td>

                  <td data-icons>
                    <div className="d-flex align-items-center">
                      <div>
                        <IoEyeOutline
                          onClick={() => handleOpenDetailsModal(customer.id)}
                          className="cursor-pointer"
                          color="#707683"
                          size={20}
                          data-tooltip-id={`eye-${customer.id}`}
                          data-tooltip-variant="dark"
                        />
                        <TooltipButton
                          id={`eye-${customer.id}`}
                          text="Ver detalhes"
                        />
                      </div>

                      <div className="ms-2">
                        <IoWalletOutline
                          onClick={() => navigate(`/customers/${customer.id}`)}
                          className="cursor-pointer"
                          color="#707683"
                          size={16}
                          data-tooltip-id={`wallet-${customer.id}`}
                          data-tooltip-variant="dark"
                        />
                        <TooltipButton
                          id={`wallet-${customer.id}`}
                          text="Ver carteira"
                        />
                      </div>

                      <div className="ms-2">
                        <BiMoneyWithdraw
                          onClick={() =>
                            handleOpenUseCashbackModal(customer.id)
                          }
                          className="cursor-pointer"
                          color="#707683"
                          size={16}
                          data-tooltip-id={`withdraw-${customer.id}`}
                          data-tooltip-variant="dark"
                        />
                        <TooltipButton
                          id={`withdraw-${customer.id}`}
                          text="Usar cashback"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <ModalCustomerDetails />
      <ModalRegisterCashbackUsage />
    </div>
  );
};
